<template>
    <div class="services">
         <generic-bg-color></generic-bg-color>
        <!-- Options -->
        <boxes-services
            :full_top="true"
            :data_box="data_box">
        </boxes-services>
        <div class="container container-indu services-text">
            <!-- ITEM -->
            <div class="item-service">
                <div class="content">
                    <h1 class="title">Servicio técnico y post venta</h1>
                    <p>
                        El área de Servicio al Cliente cuenta con el personal idóneo y las herramientas
                        necesarias para ayudarle a resolver cualquier inconveniente que pueda
                        presentarse mientras hacen uso de los productos comprados en nuestra
                        compañía. Nos aseguramos que usted este totalmente satisfecho con la calidad
                        del rendimiento de cada artículo y con nuestra eficiencia para cualquier
                        inconveniente, ofreciendo soluciones de una manera rápida y eficaz.
                    </p>
                </div>
            </div>
            <!-- ITEM -->
            <div class="item-service">
                <div class="content">
                    <h1 class="title">Servicio de mantenimiento</h1>
                    <p>
                        Iniciamos este servicio desde el momento en que entregamos el producto. Se
                        establece un tiempo de seguimiento inicial y posteriormente se planifica un tiempo
                        previo de acuerdo con el cliente. Este tiempo es determinado por obra, actividad
                        específica, horas, semanas o meses. Una vez finalizado el proyecto, nuestros
                        clientes reciben instrucciones claras y precisas para guiarles adecuadamente a
                        hacer buen uso de los productos comprados en nuestra compañía. Del mismo
                        modo, nos aseguramos que no existan inconvenientes brindando la atención
                        necesaria para cualquier servicio de mantenimiento que se requiera, una vez
                        instalado el producto.
                    </p>
                </div>
            </div>
            <!-- ITEM -->
            <div class="item-service">
                <div class="content">
                    <h1 class="title">Garantías</h1>
                    <p>
                        Todos nuestros productos cuentan con garantías ya sea directamente en
                        productos fabricados por nosotros o con garantías directas de nuestros
                        proveedores en aquellos que comercializamos. Nos aseguramos en entregar
                        productos eficientes, idóneos y de excelente calidad; esto va determinado no solo
                        por el hecho que el producto satisfaga las necesidades para las cuales haya sido
                        producido, sino también nos aseguramos en que debe cumplir con aquellas
                        condiciones que hayan sido informadas al consumidor.
                    </p>
                </div>
            </div>
        </div>

        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Nuestros Servicios',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
            { name: 'keywords', content:'industrias pico, muebles oficina, muebles oficina Bucaramanga, muebles para oficina Bucaramanga, estantería metálica, estanterías metálicas, fabricas de estanterías metálicas, divisiones para oficina Bucaramanga, escritorios gerenciales Bucaramanga, escritorios para gerencia, Archivadores metálicos, fabrica de muebles para oficina, sillas para oficinas Bucaramanga, diseño de oficinas Bucaramanga, muebles para recepción Bucaramanga' },
            { name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Nuestros Servicios'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Nuestros Servicios'},
			{name: 'twitter:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Nuestros Servicios'},
			{itemprop: 'description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    data() {
        return {
           data_box: {
                title: 'Nuestros Servicios',
                description: `
                    Nuestra compañía está 100% comprometida con brindarle a cada uno de nuestros
                    clientes un servicio de alta calidad. Entendemos que es necesario generar
                    soluciones integrales adaptadas a la necesidad de cada cliente brindando atención
                    personalizada, por tal motivo, nuestros servicios están enmarcados en un
                    ambiente de amabilidad, agilidad, asesoría, calidad y oportunidad. Nos caracteriza
                    la eficiencia y disposición durante todo el proceso de la gestión comercial.
                `,
                box_1: {
                    title: 'Técnico y post venta',
                    url_image: 'servicios/post_venta.jpg',
                },
                box_2: {
                    title: 'Mantenimiento',
                    url_image: 'servicios/mantenimiento.png',
                },
                box_3: {
                    title: 'Garantías',
                    url_image: 'servicios/garantia.png',
                }
            } 
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';
@import '@/assets/css/_mixins.scss';

.services-text {
    margin-bottom: 4rem;
    margin-top: 2rem;
    
    .item-service {
        margin-bottom: 2rem;

        .content {
            .title { font-size: 1.5rem }

            @media (min-width: 1024px) {
                width: 60%;
            }

            @media (min-width: 1200px) {
                .title { font-size: 2rem }
            }

            @media (min-width: 1900px) {
                max-width: 900px;
                .title { font-size: 2.5rem }
                p { font-size: 1.1rem }
            }
        }

        @media (min-width: 1024px) {
            &:nth-child(2) {
                display: flex;
                justify-content: flex-end;
                margin-top: 2rem;

                .content { p { margin-bottom: 0px } }
            }
        }

        @media (min-width: 1200px) {
            margin-bottom: 4rem;
            &:nth-child(2) { margin-top: 5rem }
        }
    }

    @media (min-width: 1200px) {
        margin-bottom: 5rem
    }
}
</style>